<template>
  <b-list-group class="px-2 stripe-list mb-1">
    <b-list-group-item
      v-for="account in establishmentAccounts"
      :key="account.id"
      class="d-flex align-items-center"
    >
      <div class="grow payouts-flex">
        <p class="h5 m-0">{{ account.alias }}</p>
        <div class="d-flex flex-wrap text-sm">
          Cargos:
          <b-badge
            size="sm"
            class="mx-1"
            :variant="account.charges_enabled ? 'success' : 'danger'"
          >
            {{ account.charges_enabled ? "Activado" : "Desactivado" }}</b-badge
          >
        </div>

        <div class="d-flex flex-wrap text-sm">
          Payouts
          <b-badge
            size="sm"
            class="mx-1"
            :variant="account.payouts_enabled ? 'success' : 'danger'"
          >
            {{ account.payouts_enabled ? "Activado" : "Desactivado" }}</b-badge
          >
        </div>

        <div class="d-flex flex-wrap text-sm">
          Estatus:
          <b-badge
            size="sm"
            class="mx-1"
            :variant="account.active_status ? 'success' : 'danger'"
          >
            {{ account.active_status ? "Activado" : "Desactivado" }}
          </b-badge>
        </div>
      </div>

      <b-dropdown variant="link" no-caret class="ml-auto" boundary="window">
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item @click="handleUpdateStatus(account)">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">{{
            account.active_status ? "Desactivar" : "Activar"
          }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="handleLinkedStores(account)">
          <feather-icon icon="LinkIcon" />
          <span class="align-middle ml-50">Ver tiendas vinculadas</span>
        </b-dropdown-item>
        <b-dropdown-item @click="handleSelectedStripeAccount(account)">
          <feather-icon icon="LinkIcon" />
          <span class="align-middle ml-50">Vincular tienda</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="handleOnboarding(account)"
          v-if="!account.onboarding_complete"
        >
          <feather-icon icon="LinkIcon" />
          <span class="align-middle ml-50">Completar onboarding</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-list-group-item>
    <b-modal id="linkedStoreModal" title="Tiendas vinculadas" size="lg" ok-only>
      <div class="d-flex flex-wrap gap">
        <b-badge
          v-for="(store, index) in selectedStore.linked_stores"
          size="sm"
          class="mr-1 mt-1"
          variant="light-primary"
        >
          {{ store.name }}
        </b-badge>
      </div>
    </b-modal>
  </b-list-group>
</template>

<script>
export default {
  data() {
    return {
      selectedStore: { linked_stores: null },
    };
  },
  props: {
    establishmentAccounts: {
      type: Array,
      default: () => [],
    },
    handleUpdateStatus: {
      type: Function,
      default: () => {},
    },
    handleSelectedStripeAccount: {
      type: Function,
      default: () => {},
    },
    handleOnboarding: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    handleLinkedStores(account) {
      this.selectedStore = account;
      this.$bvModal.show("linkedStoreModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.payouts-flex {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
